import React, { useEffect } from 'react'
import clx from 'classnames'
import Layout from '../components/Layout'
import { graphql, Link } from 'gatsby'
import styles from './blog-post.module.css'
import SEO from '../components/Seo'
import ShareButtons from '../components/SocialShare'
import Date from '../images/calendar_today-24px.svg'
import Image from '../components/Image'
import { Link as Tag } from '../components/Button'
import TableOfContent from '../components/TableOfContent'
import Banner from '../components/Banner'
import Lightbox from '../components/Lightbox'
import DigitalOcean from '../components/banners/DigitalOcean'

export default function BlogPost({ data, pageContext }) {
  const post = data.allWpPost.nodes[0]
  const { languages } = pageContext
  useEffect(() => {
    if (languages.length) {
      ;(async () => {
        const { default: Prisma } = await import('prismjs')
        const { default: i } = await import('../components/loadPrisma')
        i(languages, () => Prisma.highlightAll())
      })()
    }
  }, [])

  useEffect(() => {
    try {
      const adsbygoogle = window.adsbygoogle || []
      adsbygoogle.push({})
      adsbygoogle.push({})
    } catch (e) {
      console.log('unads', e)
    }
  }, [])

  useEffect(() => {
    const imgs = document.querySelectorAll('#content img')
    imgs.forEach(img =>
      img.addEventListener('click', () => {
        const event = new CustomEvent('lightbox', { detail: img })
        window.dispatchEvent(event)
      })
    )

    const twitterEmbeds = document.querySelectorAll(
      '.twitter-tweet, .twitter-timeline'
    )

    if (twitterEmbeds.length) {
      // remove twitter javascript and reload it
      // to make sure that it renders
      const twitterScript = document.querySelectorAll(
        'script[src="https://platform.twitter.com/widgets.js"]'
      )
      twitterScript.forEach(script => script.remove())
      const twitterEmbedScript = document.createElement('script')
      twitterEmbedScript.src = 'https://platform.twitter.com/widgets.js'
      twitterEmbedScript.async = true
      document.body.appendChild(twitterEmbedScript)
      console.log('twitterScript', twitterScript)
    }
  }, [])

  const fluid = post?.featuredImage?.node?.localFile?.childImageSharp?.fluid
  const altText = post?.featuredImage?.node?.altText
  const {
    content,
    title,
    seo,
    date,
    dateGmt,
    categories,
    tags,
    related_posts,
    toc,
  } = post
  const {
    breadcrumbs,
    opengraphAuthor,
    opengraphDescription,
    opengraphModifiedTime,
    opengraphPublishedTime,
    opengraphPublisher,
    opengraphSiteName,
    opengraphTitle,
    opengraphType,
    opengraphUrl,
    opengraphImage,
  } = seo

  const desktopImage =
    post.featuredImage?.node?.desktopImage?.childImageSharp?.fluid
  const mobileImage =
    post.featuredImage?.node?.mobileImage?.childImageSharp?.fluid
  const sources = [
    mobileImage,
    { ...desktopImage, media: `(min-width: 768px)` },
  ]

  const mediaWidth = opengraphImage?.mediaDetails?.width
  const mediaHeight = opengraphImage?.mediaDetails?.height
  const mediaUrl = opengraphImage?.localFile?.publicURL
  return (
    <Layout>
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        canonical={seo.canonical}
        schema={seo.schema.raw}
        featuredImage={fluid ? fluid.src : undefined}
        meta={[
          {
            name: `twitter:creator`,
            content: data.wp.seo.social.twitter.username
              ? `@${data.wp.seo.social.twitter.username}`
              : '',
          },
          { name: 'og:locale', content: 'en_US' },
          { name: 'og:type', content: opengraphType },
          { name: 'og:title', content: opengraphTitle },
          { name: 'og:description', content: opengraphDescription },
          { name: 'og:url', content: opengraphUrl },
          { name: 'og:site_name', content: opengraphSiteName },
          { name: `${opengraphType}:publisher`, content: opengraphPublisher },
          {
            name: `${opengraphType}:published_time`,
            content: opengraphPublishedTime,
          },
          {
            name: `${opengraphType}:modified_time`,
            content: opengraphModifiedTime,
          },
          {
            name: 'og:image',
            content: mediaUrl ? `https://www.polynique.com${mediaUrl}` : '',
          },
          { name: 'og:image:width', content: mediaWidth },
          { name: 'og:image:height', content: mediaHeight },
        ]}
      />
      {breadcrumbs && breadcrumbs.length && (
        <ol className={styles.breadcrumbs}>
          {breadcrumbs.map(({ text, url }) => (
            <li key={url}>
              <Link to={url} dangerouslySetInnerHTML={{ __html: text }} />
            </li>
          ))}
        </ol>
      )}
      <article>
        <h1 className={styles.title}>{title}</h1>
        <div className={styles.meta}>
          <time dateTime={dateGmt}>
            <Date /> {date}
          </time>
        </div>
        {!!(sources && sources.length) && (
          <Image className={styles.featuredimage} fluid={sources} alt={title} />
        )}
        <div className={styles.contentWrapper}>
          <div className={clx(styles.socials, styles.share)}>
            <span className={styles.shareTitle}>Share</span>
            <ShareButtons
              className={styles.shareItem}
              title={title}
              url={`https://www.polynique.com${seo.canonical}`}
            />
          </div>
          <div className={styles.postWrapper}>
            <TableOfContent toc={toc} className={styles.toc} />
            <div className={styles.articleWrapper}>
              {/* <DigitalOcean /> */}
              <div
                id="content"
                className={styles.postContent}
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <DigitalOcean />
              <div className={styles.socials}>
                If you like our post, please share it:{' '}
                <ShareButtons
                  title={title}
                  url={`https://www.polynique.com${seo.canonical}`}
                />
              </div>
              {!!(tags?.nodes && tags?.nodes.length) && (
                <div className={styles.tags}>
                  {tags.nodes.map(({ name, slug, link }) => (
                    <Tag
                      key={slug}
                      className={styles.tag}
                      to={link}
                      title={name}
                    >
                      {name}
                    </Tag>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </article>
      {/* <Banner
        slot="6984123205"
        style={{ display: 'block', textAlign: 'center' }}
        layout="in-article"
        format="fluid"
      /> */}
      {/* <Banner
        slot="4126482423"
        style={{ display: 'block' }}
        format="auto"
        responsive="true"
      /> */}
      <aside className={styles.relatedPosts}>
        <h3>Related articles</h3>
        <ul>
          {related_posts?.nodes?.map(
            ({ slug, uri, title, featuredImage, categories }) => (
              <li key={uri} className={styles.card}>
                <Link to={uri} title={title} rel="bookmark">
                  <div className={styles.cardImage}>
                    {!!(categories && categories.nodes?.length) &&
                      categories.nodes.map(({ name, uri }) => (
                        <Link
                          key={uri}
                          to={uri}
                          title={name}
                          className={styles.category}
                        >
                          {name}
                        </Link>
                      ))}
                    <Image
                      fluid={
                        featuredImage?.node?.localFile?.childImageSharp?.fluid
                      }
                      alt={title}
                      className={styles.cardImage}
                    />
                  </div>

                  <h5 className={styles.cardTitle}>{title}</h5>
                </Link>
              </li>
            )
          )}
        </ul>
      </aside>
      <Lightbox />
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    wp {
      seo {
        social {
          twitter {
            username
          }
        }
      }
    }
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        date(formatString: "DD MMM, yyyy")
        dateGmt
        seo {
          canonical
          metaDesc
          focuskw
          metaKeywords
          title
          metaRobotsNofollow
          metaRobotsNoindex
          breadcrumbs {
            text
            url
          }
          schema {
            raw
            articleType
            pageType
          }
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          opengraphImage {
            mediaDetails {
              height
              width
            }
            localFile {
              publicURL
            }
          }
        }
        categories {
          nodes {
            name
            link
            slug
          }
        }
        tags {
          nodes {
            name
            link
            slug
          }
        }
        toc
        featuredImage {
          node {
            altText
            seo {
              metaDesc
            }
            mobileImage: localFile {
              childImageSharp {
                fluid(maxWidth: 400, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            desktopImage: localFile {
              childImageSharp {
                fluid(maxWidth: 1160, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        related_posts {
          nodes {
            title
            slug
            uri
            categories {
              nodes {
                uri
                name
              }
            }
            featuredImage {
              node {
                altText
                seo {
                  metaDesc
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 200, maxHeight: 110, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

import React from 'react'
import clx from 'classnames'
import { container, title } from './TableOfContent.module.css'
import Banner from './Banner'

interface TOC {
  url: string
  title: string
  depth: number
  items?: TOC[]
}

interface TableOfContentProps {
  toc: { items: TOC[] }
  className?: string
}

const renderTableOfContentItems = (items: TOC[]) => {
  return (
    <ol>
      {items.map(({ url, title, items }) => (
        <li key={url}>
          <a href={url}>{title}</a>
          {items && items.length && renderTableOfContentItems(items)}
        </li>
      ))}
    </ol>
  )
}

const TableOfContent: React.FC<TableOfContentProps> = ({ toc, className }) => (
  <aside className={className}>
    <div className={container}>
      <h2 className={title}>Table of contents</h2>
      {renderTableOfContentItems(toc.items)}
    </div>
    <Banner
      slot="3996326775"
      style={{
        display: 'inline-block',
        marginTop: '1rem',
        width: '300px',
        height: '250px',
      }}
    />
  </aside>
)

export default TableOfContent

import React, { useEffect, useCallback, useState } from 'react'
import clx from 'classnames'
import { container, image } from './Lightbox.module.css'

export interface LightboxEvent extends CustomEvent {
  detail: HTMLImageElement
}

declare global {
  interface WindowEventMap {
    lightbox: LightboxEvent
  }
}

interface State {
  src?: string
  orientation?: 'vertical' | 'horizontal'
}

const Lightbox = () => {
  const [state, setState] = useState<State>({})
  const onLightbox = useCallback((e: LightboxEvent) => {
    const { detail } = e
    const { src, clientHeight, clientWidth } = detail
    console.log({ clientHeight, clientWidth })
    setState({
      src,
      orientation: clientHeight >= clientWidth ? 'vertical' : 'horizontal',
    })
  }, [])

  useEffect(() => {
    window.addEventListener('lightbox', onLightbox)
    return () => {
      window.removeEventListener('lightbox', onLightbox)
    }
  }, [])

  if (!state.src) return null

  return (
    <div className={container} onClick={() => setState({})}>
      <img className={clx(image, state.orientation)} src={state.src} />
    </div>
  )
}

export default Lightbox

import React from 'react'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
} from 'react-share'

interface ShareButtonsProps {
  title: string
  url: string
  className?: string
}

const ShareButtons: React.FC<ShareButtonsProps> = ({
  title,
  url,
  className,
  // twitterHandle,
  // tags,
}) => {
  return (
    <>
      <FacebookShareButton url={url} className={className}>
        <FacebookIcon size={40} round={true} />
      </FacebookShareButton>
      {/* <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
      >
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton> */}
      <LinkedinShareButton url={url} className={className}>
        <LinkedinIcon size={40} round={true} />
      </LinkedinShareButton>
      <RedditShareButton url={url} title={title} className={className}>
        <RedditIcon size={40} round={true} />
      </RedditShareButton>
      <WhatsappShareButton url={url} title={title} className={className}>
        <WhatsappIcon size={40} round={true} />
      </WhatsappShareButton>
      <TwitterShareButton url={url} title={title} className={className}>
        <TwitterIcon size={40} round={true} />
      </TwitterShareButton>
    </>
  )
}
export default ShareButtons
